import { navigate } from 'gatsby-plugin-react-intl'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

import DatacError from '../../assets/images/datac-error.svg'
import DatacSuccess from '../../assets/images/datac-success.svg'
import { Layout } from '../../components/auth/Layout'
import { SimpleContent } from '../../components/auth/SimpleContent'
import { useScopedIntl } from '../../hooks'
import { AccountType, verifyAccount } from '../../requests'
import { routes } from '../../routes'

interface Props {
  location: {
    search: string
    state?: {
      email?: string
    }
  }
}

const SignUpConfirmationPage: React.FC<Props> = ({ location }) => {
  const intlSignupSuccess = useScopedIntl('auth.signup_confirmation')
  const [accountVerified, setAccountVerified] = useState<boolean>(null)
  const { id, token } = queryString.parse(location.search)

  useEffect(() => {
    verifyAccount(
      { token: token as string, accountId: id as string, accountType: AccountType.User },
      {
        onSuccess: () => setAccountVerified(true),
        onRequestError: () => setAccountVerified(false),
        onNoSuchInvitation: () => navigate(routes.signIn(AccountType.User))
      }
    )
  }, [])

  const onSubmit = () => {
    navigate(routes.signIn(AccountType.User))
    return Promise.resolve()
  }

  return (
    <Layout accountType={AccountType.User}>
      {accountVerified === null ? null : (
        <SimpleContent
          title={intlSignupSuccess('title')}
          subtitle={intlSignupSuccess(accountVerified ? 'subtitle_success' : 'subtitle_error')}
          message={intlSignupSuccess(accountVerified ? 'message_success' : 'message_error')}
          submit={intlSignupSuccess('submit')}
          Image={accountVerified ? DatacSuccess : DatacError}
          onSubmit={onSubmit}
          showSubmit={accountVerified}
        />
      )}
    </Layout>
  )
}

export default SignUpConfirmationPage
export const Head = () => <title>Datacapt - Sign Up</title>
